@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Paytone+One&display=swap');

#root {
    min-height: 100vh;
    display: block;
    position: relative;
    div.body {
        padding-top: 80px;
        padding-bottom: 20px;
        background-color: #131A21;
        border: none;
        div.powerbi-frame {
            height: 100%;
            border: none;
            iframe {
                border: none;
            }
        }
        div.double-panel {
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            min-height: 85vh;
            div.main-description {
                span.main-text-title {
                    color: white;
                    font-family: 'Roboto', sans-serif;
                    font: Bold 53px/54px Roboto;
                }
                span.main-text-desc {
                    font-family: 'Roboto', sans-serif;
                    color: white;
                    font: Regular 16px/21px Roboto;
                    letter-spacing: 0;
                }
                div.icons-container {
                    display: flex;
                    align-items: center;
                    margin: 12px;
                    div#pbi-icon {
                        background: transparent url(../images/png/Microsoft-Power-BI-01.png) 0% 0% no-repeat padding-box;
                        height: 30px;
                        width: 120px;
                    }
                    div#msft-icon {
                        background: transparent url(../images/png/XMLID_1_.png) 0% 0% no-repeat padding-box;
                        height: 30px;
                        width: 120px;
                    }
                }
            }
        }
        div.learn-more {
            display: flex;
            justify-content: center;
            align-items: center;
            button.learn-more {
                padding: 10px;
            }
        }
        div.cards-main.container {
            div.cards-description {
                span.cards-main-text-title {
                    font-family: 'Roboto', sans-serif;
                    color: white;
                    font: 40px/45px Roboto;
                    letter-spacing: 0;
                }
                span.cards-main-text-desc {
                    font-family: 'Roboto', sans-serif;
                    color: white;
                    font: 14px/16px Roboto;
                    letter-spacing: 0;
                }
            }
            div.cards-container {
                // display: flex;
                // flex-wrap: wrap;
                div.cards {
                    flex: 1;
                    background-color: #232732;
                    border-radius: 6px;
                    box-shadow: 0px 3px 8px #3E649228;
                    margin: 7px;
	                padding: 20px;
                    span.cards-text-title {
                        font-family: 'Roboto', sans-serif;
                        color: white;
                        font: Bold 18px/21px Roboto;
                    }
                    span.cards-text-desc {
                        font-family: 'Roboto', sans-serif;
                        color: white;
                        font: 14px/16px Roboto;
                        letter-spacing: 0;
                        margin: 5px;
                    }
                }
            }
        }
    }
}

header {
    background-color: #14181E;
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

div.svg-bg {
    background: #1D212D 0% 0% no-repeat padding-box;
    border-radius: 100px;
    padding: 8px;
    div.svg-test {
        z-index: 100;
        background: url(../images/svg/share-2.svg) no-repeat;
        height: 24px;
        width: 24px;
        background-position: center;
    }
}

footer {
    background-color: #131A21;
    color: white;
}

div.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 70px;
    max-width: 100vw;
    margin-left: 10px;
    margin-right: 10px;
    div#cleverit-logo {
        background: url(../images/svg/Logo-cleverit-b.svg) no-repeat;
        background-size: 120px 100%;
        height: 100%;
        width: 120px;
        cursor: pointer;
    }
    div#cleverdata-logo {
        background: url(../images/svg/clever_data_light.svg) no-repeat;
        background-size: 120px 100%;
        height: 100%;
        width: 120px;
        cursor: pointer;
    }
}

div.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: white;
    background-color: #131A21;
    width: 100%;
    span {
        font-family: 'Roboto', sans-serif;
        color: white;
        opacity: 0.34;
    }
}


div.container-js {
    background-color:rgb(255,254,248);
    box-shadow: 2px 2px 6px rgba(50, 50, 50, 0.75);
    padding: 15px;
    span {
        font-family: 'Roboto', sans-serif;
        font: Bold 15px/16px;
    }
    div.main.js {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        div.main-cards {
            div.main-cards-div {
                text-align: center;
                font-family: 'Roboto', sans-serif, 'bold';
                font-size: 28px;  
            }
        }
    }
    div.section-js {
        height: 65vh;
        min-height: 400px;
        position: relative;
        div.background-holder {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            height: 100%;
            width: 100%;
            position: absolute;
        }
        div.background-holder-charts {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            height: 100%;
            width: 100%;
            position: absolute;
            opacity: 35%;
        }
        div.section-js-chart-holder {
            opacity: 0.8;
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }
    div.chart-js-header {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        padding: 14px;
        padding-bottom: 20px;
        font-family: 'Roboto', sans-serif;
        font: 18px/24px Roboto;

        div.share-container {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                margin-right: 5px
            }
            div.share-icon {
                padding-top: 8px;
                padding-bottom: 8px;
                padding-left: 9.75px;
                padding-right: 9.75px;
                background: #1D212D 0% 0% no-repeat padding-box;
                border-radius: 100px;
                background-position: center;
            } 
        }
        
    }
    div.chart-js-footer {
        font-family: 'Roboto', sans-serif;
        color: #FFFFFF;
        padding: 10px 16px 16px 16px;
        font-size: 16px;
        text-align: center;
    }
}

div.container.js {
    div.encuesta.js {
        height: 120vh !important;
    }
}

div.logoBarWrapper {
    div.unselectable {
        > div.logoBar {
            background-color: red;
        }
    }
}

div.disclaimer-container {
    font-family: 'Roboto', sans-serif;
    background-color: #232732;
    color: white;
    padding: 25px;
}

span.anchors {
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 18px;
    text-decoration: underline;
}

span.anchors-dot {
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 18px;
}

.leaflet-container {
    height: 65vh;
  }

#scroll-up {
    /* display: none; */
    position: fixed; /* Fixed/sticky position */
    left: 15px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #1D212D; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
    border-color: rgba(255, 255, 255, 0.7);
    text-align: center;
    border-style: solid;
    i {
        margin: 0px !important;
    }
  }


@media (min-width: 768px) {
#scroll-up {
bottom: 20px; /* Place the button at the bottom of the page */
    }
    div.header-container {
        justify-content: space-around;
    }
}

@media (max-width: 767.8px) {
#scroll-up {
    background-color: #14181E;
    padding-left: 40px;
    padding-right: 40px;
    top: 0px;
    padding-top: 13px;
    padding-bottom: 13px;
    z-index: 9999;
    margin: 0% auto;
    left: 44%;
    border-style: none;
    }
    div.header-container {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 55px;
}
}

@media (max-width: 470px) {
#scroll-up {
    background-color: #14181E;
    padding-left: 30px;
    padding-right: 30px;
    top: 0px;
    padding-top: 13px;
    padding-bottom: 13px;
    z-index: 9999;
    margin: 0% auto;
    left: 42%;
    border-style: none;
    }
}

@media (max-width: 420px) {
#scroll-up {
    background-color: #14181E;
    padding-left: 20px;
    padding-right: 20px;
    top: 0px;
    padding-top: 13px;
    padding-bottom: 13px;
    z-index: 9999;
    margin: 0% auto;
    left: 41%;
    border-style: none;
    }
}

@media (max-width: 370px) {
#scroll-up {
    background-color: #14181E;
    padding-left: 10px;
    padding-right: 10px;
    top: 0px;
    padding-top: 13px;
    padding-bottom: 13px;
    z-index: 9999;
    margin: 0% auto;
    left: 40%;
    border-style: none;
    }
}

div.col-12.col-xl-8 {
    padding-left: 0px;
    padding-right: 0px;
}

.chartjs-size-monitor {
    background-color: #232732;
}

.column-holder {
    display: flex;
    flex-wrap: wrap;
}

.cc {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-title {
    font-family: 'Paytone One', sans-serif;
    font-size: 5rem;
    font-weight: 500;
    letter-spacing: -0.0em;
    line-height: 1.1;
    text-transform: none;
}

h3.wine {
    color: #270609;
}

h4.wine {
    color: #270609;
}

h3, h4 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600 !important;
    letter-spacing: 0.13em;
    line-height: 1.75;
    text-transform: uppercase;
}


footer > #footer.column-holder > div.chart-info-side > div.chart-info-side-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1vh !important;
    padding-bottom: 1vh !important;
    color: black;
}
footer > #footer.column-holder > .footer-info {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: rgb(255,254,248);
    padding: 1vh !important;
    box-shadow: 2px 2px 6px rgba(50, 50, 50, 0.75);
}


@media (min-width: 1200px) {
    #intro {
        #intro-des {
            h1:after {
                top: 2rem;
            }
            h3:after {
                top: 2rem;
            }
            div.chart-info-side-des-title-holder:after {
                left: 4px;
                width: 25px;
                background: white;
                content: '';
                display: block;
                height: 2px;
                position: relative;
                top: 2rem;
            }
            .chart-info-side {
                color: white;
                height: 100%;
                .chart-info-side-des {
                    height: 100%;
                }
            }
        }
        #resumen {
            padding: 60px;
            padding-bottom: 20px;
        }
    }


    .chart-info-side {
        background-color: #7fd0d0;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        .chart-info-side-holder {
            height: 100%;
            padding-top: 30%;
            margin-right: 10%;
            border-width: 3px;
            border-color: white;
            border-right-style: solid;
            .chart-info-side-des {
                padding-right: 35px;
                text-align: right;
                div.dropdown-share-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 15px;
                    color: black;
                    div.dropdown {
                        margin-left: 8px;
                        select {
                            font: Regular 15px/18px Roboto;
                            background: #fffef8 0% 0% no-repeat padding-box;
                            border-color: rgba(112, 112, 112, 0.5);
                            padding: 3px;
                        }
        
                    }
                }
                div.chart-info-side-des-title-holder {
                    margin-right: -33px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-end;
                    right: -5px;
                    h1 {
                        margin-right: -6px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;
                        right: -5px;
                    }
                    h1:after {
                        margin-top: -2px;
                        min-width: 7px;
                        max-width: 7px;
                        height: 7px;
                        border-radius: 7px;
                        background: #fff;
                        content: '';
                        display: block;
                        position: relative;
                        top: 1rem;
                        margin-left: 5px
                    }
                    h3 {
                        margin-right: -6px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;
                        right: -5px;
                    }
                    h3:after {
                        margin-top: -2px;
                        min-width: 7px;
                        max-width: 7px;
                        height: 7px;
                        border-radius: 7px;
                        background: #fff;
                        content: '';
                        display: block;
                        position: relative;
                        top: 1rem;
                        margin-left: 5px
                    }
                }
                div.chart-info-side-des-title-holder:after {
                    left: 4px;
                    min-width: 25px;
                    max-width: 25px;
                    background: white;
                    content: '';
                    display: block;
                    height: 2px;
                    position: relative;
                    top: 1rem;
                }
            }
        }
    }
    #intro-des {
        background: url(../images/capture-covid.jpg) no-repeat;
        height: 100vh;
        background-size: auto 100%;
        min-height: 1000px;
    }
    .dashboard-holder {
        padding: 10px 90px 10px 90px;
        .intro-data-block {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            .intro-data-block-card {
                margin: 30px;
                height: 210px;
                min-width: 300px;
                border-radius: 16px;
                svg {
                    width: 100px;
                    height: 120px;
                }
            }
        }
    }
    #logo-holder {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
            width: 220px;
            height: 100px;
            display: block;
            padding: 25px;
            box-sizing: content-box;
        }
    }
}
    
@media (max-width: 1199.5px) {
    .chart-info-side {
        background-color: #7fd0d0;
        display: flex;
        justify-content: center;
        align-items: baseline;
        box-shadow: 2px 2px 6px rgba(50, 50, 50, 0.75);
        .chart-info-side-holder {
            padding: 3vh;
        }
        div.dropdown-share-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 15px;
            color: black;
            div.dropdown {
                margin-left: 8px;
                select {
                    font: Regular 15px/18px Roboto;
                    background: #fffef8 0% 0% no-repeat padding-box;
                    border-color: rgba(112, 112, 112, 0.5);
                    padding: 3px;
                }

            }
        }
    }

    #logo-holder {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        svg {
            width: 220px;
            height: 100px;
            padding-left: 0px;
            path {
                fill: black
            }
            rect {
                fill: black
            }
        }
    }

    #intro {
        #intro-des {
            background-color: rgb(136, 207, 209);
            background-size: auto 100%;
            .chart-info-side {
                justify-content: flex-start !important;
                .chart-info-side-holder {
                    margin-top: 5% !important;
                }
            }
        }
        #resumen {
            padding: 30px;
        }
    }


    .dashboard-holder {
        padding: 0px 60px 0px 60px;
        .intro-data-block {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            .intro-data-block-card {
                height: 100%;
                min-width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                min-height: 200px;
                svg {
                    width: 100px;
                    height: 120px;
                }
            }
        }
    }
}

@media (max-width: 767.5px) {
    .dashboard-holder {
        padding: 0px;
        padding-bottom: 10px;
    }
    .main-title {
        font-size: 3rem;
    }
    #intro-des {
        .chart-info-side {
            .chart-info-side-holder {
                margin-top: 2% !important;
            }
        }
    }
}